import { createError, navigateTo, defineNuxtRouteMiddleware } from '#imports'

import { useDebugMode } from '@/composables/useDebugMode'

export default defineNuxtRouteMiddleware((to) => {
  const debugMode = useDebugMode()

  if ('enable' in to.query) {
    debugMode.enable()

    return navigateTo({ ...to, query: { ...to.query, enable: undefined } })
  }

  if ('disable' in to.query) {
    debugMode.disable()

    return navigateTo({ name: 'index' })
  }

  if (!debugMode.enabled) {
    return createError({ statusCode: 404 })
  }
})
